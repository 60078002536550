import axios from "@/utils/requestApi"

//post
export function postActionApi(url, parameter) {
  return axios({
    url: url,
    method:'post' ,
    data: parameter
  })
}

//put
export function putActionApi(url, parameter) {
  return axios({
    url: url,
    method:'put',
    data: parameter
  })
}

//get
export function getActionApi(url, parameter) {
  return axios({
    url: url,
    method: 'get',
    params: parameter
  })
}

//delete
export function deleteActionApi(url, parameter) {
  return axios({
    url: url,
    method: 'delete',
    data: parameter
  })
}

export function patchActionApi(url, parameter) {
  return axios({
    url: url,
    method:'patch',
    data: parameter
  })
}

export function downFileApi(url, parameter){
  return axios({
    url: url,
    params: parameter,
    method:'get' ,
    responseType: 'blob'
  })
}

export function postFileApi(url, parameter){
  return axios({
    url: url,
    params: parameter,
    method:'post' ,
    responseType: 'blob'
  })
}

//post
export function postSetHeaderActionApi(url, parameter, header) {
  return axios({
    url: url,
    method:'post' ,
    data: parameter,
    headers: header
  })
}
