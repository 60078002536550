<template>
  <div id="home">

    <div class="content">
      <div class="title">
        Web Inquiries
      </div>
      <div class="center">
        <el-input placeholder="Search here" v-model="search" class="input-with-select" style="width: 300px;">
          <el-button slot="append" icon="el-icon-search" @click="search_list"></el-button>
        </el-input>
      </div>
      <el-table
        v-loading="table_loading"
        :data="tableData"
        :header-cell-style="{ fontSize: '14px', backgroundColor: '#F8F9FA',color:'#4A5468',fontWeight:'normal'}"
        :cell-style="{ fontSize: '14px'}"
        @sort-change="order_change"
        :default-sort = "{prop: 'date', order: 'descending'}"
        style="width: 100%;">

        <el-table-column type="expand">
          <template slot-scope="props">
            <div style="padding: 0 30px;">
              <el-descriptions title="Message" :colon="false">
                  <el-descriptions-item label="">{{props.row.messages}}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions title="Scheduled Date" :colon="false">
                  <el-descriptions-item label="">{{moment(props.row.required_datetime).format('MM/DD/YYYY HH:mm A')}}</el-descriptions-item>
              </el-descriptions>
              <div style="font-size: 16px;font-weight: bold;color:#303133;">More Information</div>
              <div>
                <json-viewer :data="props.row.kwargs"></json-viewer>
              </div>
            </div>

          </template>
        </el-table-column>

        <el-table-column
          align="left"
          min-width="180"
          label="Name">
          <template slot-scope="scope">
            {{scope.row.name}}
          </template>
        </el-table-column>

        <el-table-column
          align="left"
          min-width="200"
          label="Email">
          <template slot-scope="scope">
            {{scope.row.email}}
          </template>
        </el-table-column>

        <el-table-column
          align="left"
          min-width="180"
          label="Phone Number">
          <template slot-scope="scope" v-if="scope.row.phone_number">
            {{scope.row.phone_number}}
          </template>
        </el-table-column>

        <el-table-column
          align="left"
          min-width="200"
          label="Message">
          <template slot-scope="scope">
            <div style="display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;">{{scope.row.messages}}</div>
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="date"
          min-width="180"
          sortable="custom"
          label="Created Date">
          <template slot-scope="scope">
            {{moment(scope.row.created_datetime).format('MM/DD/YYYY HH:mm A')}}
          </template>
        </el-table-column>

      </el-table>
      <div class="pager">
        <el-pagination
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="page_size"
          :total="totalRows">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { getActionApi } from '@/network/manageApi';
  import moment from 'moment/moment';
  import jsonViewer from '../components/JsonViewer'
  export default {
    name: 'WebInquiries',
    components: {
      jsonViewer
    },
    data() {
      return {
        totalRows: 0,
        current_page: 1,
        page_size: 10,
        search: "",
        tableData: [],
        table_loading: false,
        sort: 'desc'
      }
    },
    computed: {
    },
    mounted() {

    },
    created(){
      this.getDatas(1)
    },
    methods:{
      search_list() {
        this.getDatas(1)
      },
      order_change(column, prop, order ) {
        if(column.order == "ascending") {
          this.sort = "asc"
        } else {
          this.sort = "desc"
        }
        this.getDatas()
      },
      moment,
      format_phone(localNumber, countryCode) {
        const phoneFormats = {          "+1": "(XXX) XXX-XXXX", // USA, Canada          "+44": "07XXX XXXXXX",   // UK Local Mobile          "+91": "XXXXX-XXXXX",   // India          "+61": "XXXX XXX XXX",  // Australia          "+81": "XXX-XXXX-XXXX", // Japan          "+49": "XXXX XXXXXXX",  // Germany          "+33": "X XX XX XX XX",  // France          "+55": "(XX) XXXXX-XXXX" // Brazil        };
        let format = phoneFormats[countryCode] || "XXXX-XXXX-XXXX";
        let digits = localNumber.replace(/\D/g, "");
        let formattedNumber = "";        let digitIndex = 0;        // +44 07577 318158        for (let char of format) {            if (char === "X" && digitIndex < digits.length) {                formattedNumber += digits[digitIndex];                digitIndex++;            } else {                formattedNumber += char;            }        }        return `${countryCode} ${formattedNumber}`;      },
      process_phone_number_format(phone) {
        if(!phone) {
          return "";
        }
        if(phone == "Anonymous"
        || phone == "anonymous") {
          return "Anonymous";
        }
        let countryCode = this.get_phone_countryCode(phone)
        let phone_cl = phone.replaceAll("-", "").replaceAll(countryCode, "");
        if(countryCode == "+44") {
          if(phone_cl.indexOf("7") == 0 && phone_cl.length == 10) {
            phone_cl = phone_cl.substring(1);
          } else if(phone_cl.indexOf("07") == 0 && phone_cl.length == 11) {
            phone_cl = phone_cl.substring(2);
          }
        }
        return this.format_phone(phone_cl,countryCode)
      },
      get_phone_countryCode(phone) {
        if(phone.indexOf("+55") != -1) {
          return "+55";
        } else if(phone.indexOf("+33") != -1) {
          return "+33";
        } else if(phone.indexOf("+49") != -1) {
          return "+49";
        } else if(phone.indexOf("+81") != -1) {
          return "+81";
        } else if(phone.indexOf("+61") != -1) {
          return "+61";
        } else if(phone.indexOf("+91") != -1) {
          return "+91";
        } else if(phone.indexOf("+44") != -1) {
          return "+44";
        } else if(phone.indexOf("+1") != -1) {
          return "+1";
        } else {
          return ""
        }
      },
      getDatas(initSize) {
        if(initSize != null) {
          this.current_page = initSize
        }
        this.table_loading = true
        let param = {sort_date: this.sort}
        if(this.search != null && this.search != undefined) {
          param.search = this.search
        }
        getActionApi("/inquiry/website/contact?size=" + this.page_size + "&page=" + this.current_page,param).then((res) => {
          this.tableData = res.data
          this.totalRows = res.page.totalElements
        }).catch((err) => {

        }).finally((res) => {
          this.table_loading = false
        })
      },
      handleCurrentChange(val) {
        this.getDatas(val)
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;word-break:normal;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .content{background-color: #ffffff;padding: 30px;display: grid;gap: 20px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .title div{display: flex;align-items: center;}
  .title div *{padding:10px 16px;font-size: 14px;}
  .pager{display: flex;justify-content: center;}
  .font-size-16 {font-size: 20px;}
  .margin-top-switch {margin-top: 10px;}
  .center{display: flex;justify-content: flex-end;}
  .avatar-uploader-icon {font-size: 18px;color: #8c939d;}
  .headImageShow {width: 76px;height: 76px;border-radius: 4px;}
  @media (max-width: 768px) {
    .title{flex-direction: column;}
    .title div{margin-top:20px;}
    .center{display: flex;justify-content: center;}
    .content{padding:20px 5px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }
</style>
