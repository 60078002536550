<template>
  <div id="home">

    <div class="div" v-loading="form_loading">
      <el-empty description="No Invoice Payable" v-loading="paymentLoading || paymentDetailLoading" v-if="(paymentLoading || paymentDetailLoading || paymentList.length == 0) && paymentDetail == null"></el-empty>
      <div class="title" v-if="!paymentDetailLoading && !paymentLoading && paymentList.length != 0">
        Bills
        <div v-if="paymentDetail != null">
          Invoice
          <el-select v-model="value" @change="selectChange">
            <el-option
              v-for="item,index in paymentList"
              :key="index"
              :label="'DITA-' + auto_complete_placeholder(5, item.invoice_number) + ' Unpaid'"
              :value="item.client_invoice_uuid">
              <div style="font-size: 12px;color:#333333;">DITA-{{auto_complete_placeholder(5, item.invoice_number)}} <span style="color:red;">Unpaid</span></div>
            </el-option>
          </el-select>
        </div>
        <div v-if="paymentDetail != null">
          Due
          <span class="span">{{moment(paymentDetail.due_date).format("MMM D, yyyy")}}</span>
        </div>
        <span v-if="paymentDetail != null" class="btn" @click="downloadFunction" v-loading="downloadLoading">Download</span>
      </div>
      <div v-if="paymentDetail != null">
        <div class="payment" v-loading="paymentDetailLoading">
          Amount
          <div class="conp">
            ${{paymentDetail.total_amounts}}
            <div class="record">
              <p v-for="item,index in paymentDetail.items" :key="index">
                {{item.item_description}}
                <b>${{item.amounts}}</b>
              </p>
              <p>
                Total
                <b>${{paymentDetail.total_amounts}}</b>
              </p>
            </div>
          </div>
        </div>

        <div class="torbar">
          <div class="act">
            Credit Cards Payment
          </div>
          <div>
          </div>
        </div>

        <form onsubmit="return false;">
          <div class="form">
            <div class="group">
              <label>
                <span>Card number</span>
                <div id="staxjs-number" class="input-box" style="height:40px;line-height: 1;padding-left:10px;padding-top:3px;"></div>
              </label>
              <div class="groupDiv">
                <label>
                  <span>CVV</span>
                  <div id="staxjs-cvv" class="input-box" style="height:40px;line-height: 1;padding-left:10px;padding-top:3px;"></div>
                </label>
                <label>
                  <span>Expiration date</span>
                  <el-date-picker
                        v-model="pay_order_obj.expiration"
                        style="width: 100%;"
                        type="month"
                        placeholder="MM/YYYY"
                        format="MM/yyyy"
                        value-format="MM/yyyy"
                        placement="bottom-start">
                      </el-date-picker>
                </label>
              </div>
              <div></div>
              <label>
                <span>Name</span>
                <div style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
                    <input v-model="pay_order_obj.first_name" autocomplete="off" class="field input-box" style="width:49%;" placeholder="First name"/>
                    <input v-model="pay_order_obj.last_name" autocomplete="off" class="field input-box" style="width:49%;" placeholder="Last name"/>
                </div>
              </label>
             <!-- <label>
                <span>Last name</span>
                <input v-model="pay_order_obj.last_name" autocomplete="off" class="field input-box" placeholder="Doe"/>
              </label> -->
              <div></div>
              <div></div>
              <label>
                <span>Address</span>
                <input v-model="pay_order_obj.address" class="field input-box" autocomplete="off"  placeholder="ex. Street number, house number"/>
              </label>
              <label>
                <span>City</span>
                <input v-model="pay_order_obj.city" class="field input-box" autocomplete="off"  placeholder="ex. City name"/>
              </label>
              <label>
                <span>State</span>
                <el-select v-model="pay_order_obj.state" style="width: 100%;" placeholder="State">
                  <el-option
                    v-for="item,index in stateList"
                    :key="index"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </label>
              <label>
                <span>ZIP</span>
                <input v-model="pay_order_obj.zip_code" class="field input-box" autocomplete="off"  placeholder="ex. 10019, 10022"/>
              </label>
              <label>
                <span>Phone</span>
                <input v-model="pay_order_obj.phone" class="field input-box" autocomplete="off"  placeholder="+1000000000000"/>
              </label>
            </div>
          </div>
          <div class="title" style="margin-top: 30px;justify-content: center;">
            <div></div>
            <span class="btn" @click="pay_now">Pay ${{paymentDetail.total_amounts}}</span>
          </div>
        </form>

      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { getActionApi,downFileApi, postActionApi,postSetHeaderActionApi } from '@/network/manageApi';
  import moment from 'moment/moment';
  import CryptoJS from 'crypto-js'
  export default {
    name: 'Bills',
    components: {
    },
    data() {
      return {
        paymentList:[],
        value:'',
        paymentDetail:null,
        downloadLoading:false,
        paymentLoading:false,
        paymentDetailLoading:false,
        invoice_obj: {},
        stax_js_token: process.env.VUE_APP_STAX_TOKEN,
        staxjs: null,
        error_message: "",
        check_form: false,
        form_loading: false,
        user_info: {},
        pay_order_obj: {},
        stateList:['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY']
      }
    },
    computed: {

    },
    mounted() {
      getActionApi('/user-management/my-information').then(res => {
        this.user_info = res.data
        this.pay_order_obj.city = this.user_info.city;
        this.pay_order_obj.address = this.user_info.address1;
        this.pay_order_obj.zip_code = this.user_info.zip_code;
        this.pay_order_obj.phone = this.user_info.phone_number;
        this.pay_order_obj.state = this.user_info.state;
      })
      this.value = this.$route.query.uuid
      this.loadPayment()
    },
    created(){
    },
    methods:{
      pay_now() {
        if(!this.check_form) {
          this.$message.error(this.error_message);
          return;
        }
        if(!this.pay_order_obj.expiration) {
          this.$message.error("Please enter the expiration");
          return;
        }
        if(!this.pay_order_obj.first_name) {
          this.$message.error("Please enter the first name");
          return;
        }
        if(!this.pay_order_obj.last_name) {
          this.$message.error("Please enter the last name");
          return;
        }
        if(!this.pay_order_obj.address) {
          this.$message.error("Please enter the address");
          return;
        }
        if(!this.pay_order_obj.city) {
          this.$message.error("Please enter the city");
          return;
        }
        if(!this.pay_order_obj.state) {
          this.$message.error("Please select the state");
          return;
        }
        if(!this.pay_order_obj.zip_code) {
          this.$message.error("Please enter the ZIP");
          return;
        }
        if(!this.pay_order_obj.phone) {
          this.$message.error("Please enter the phone");
          return;
        }



        let lineItems = []
        for(let item of this.paymentDetail.items) {
          let line = {}
          line.id = item.client_invoice_items_uuid
          line.item = item.item_type
          line.details = item.item_description
          line.quantity = 1
          line.price = item.amounts
          lineItems.push(line);
        }

        let extraDetails = {
          total: this.paymentDetail.total_amounts,
          firstname: this.pay_order_obj.first_name,
          lastname: this.pay_order_obj.last_name,
          company: '',
          email: '',
          month: this.pay_order_obj.expiration.split("/")[0],
          year: this.pay_order_obj.expiration.split("/")[1],
          phone: this.pay_order_obj.phone,
          address_1: this.pay_order_obj.address,
          address_2: '',
          address_city: this.pay_order_obj.city,
          address_state: this.pay_order_obj.state,
          address_zip: this.pay_order_obj.zip_code,
          address_country: '',
          url: "https://app.staxpayments.com/#/bill/",
          method: 'card',
          validate: false,
          meta: {
            reference: "DITA-" + this.auto_complete_placeholder(5, this.paymentDetail.invoice_number),
            memo: this.paymentDetail.invoice_date + " Invoice",
            otherField1: this.paymentDetail.client_invoice_uuid,
            subtotal: this.paymentDetail.total_amounts,
            tax: 0,
            lineItems: lineItems
          }
        };

        this.form_loading = true
        let uuid = this.value
        getActionApi('/payment/invoices/' + this.value).then(res => {
          if(res.data.invoice_status == "OVERDUE"
          || res.data.invoice_status == "OPEN") {

            this.staxjs.pay(extraDetails)
            .then((response) => {
              if(response.child_transactions[0].success) {
                let result = this.generate_signatures_parameters(response.child_transactions[0])
                postSetHeaderActionApi("/payment/paid", result.data, {signature: result.signature})
                .then(res => {
                  this.$message.success("Pay Success");
                  this.form_loading = false
                  this.loadPage()
                  this.$router.push({
                    path: '/billsDetail',
                    query: {
                      uuid: uuid
                    }
                  });
                }).catch((err) => {
                  this.$message.error("Payment encountered problems, please contact the administrator");
                  this.form_loading = false
                })
              }
            }).catch((err) => {
              if(err.code == "logic_error") {
                this.$message.error(err.message);
              } else if(err.payment_attempt_failed) {
                this.$message.error(err.payment_attempt_message + ",Please refresh and try again.");
              } else {
                this.$message.error("Encountered an error,Please refresh and try again.");
              }
              this.form_loading = false
            });
          } else {
            this.$message.error("The current invoice cannot be paid，Please refresh and try again。");
            this.form_loading = false
          }
        }).catch((err) => {
          this.$message.error("Encountered an error, please refresh and try again!");
          this.form_loading = false
        })
      },
      generate_signatures_parameters(transactions) {
        let result = {}
        const created_at = moment(transactions.created_at).format('YYYY-MM-DDTHH:mm:ss');
        const updated_at = moment(transactions.updated_at).format('YYYY-MM-DDTHH:mm:ss');
        let data = {
        "client_invoice_uuid": transactions.meta.otherField1,
        "transaction_uuid": transactions.id,
        "transaction_type": transactions.type,
        "paid_amount": transactions.total,
        "success": true,
        "message": "",
        "method": transactions.method,
        "created_at": created_at,
        "updated_at": updated_at,
        }
        result.data = data
        result.signature = this.generateHMACSignature(data)
        return result;
      },
      generateHMACSignature(data) {
        let obj = JSON.stringify(data)
        const hmac = CryptoJS.HmacSHA256(obj, process.env.VUE_APP_SECRETKEY);
        return hmac.toString(CryptoJS.enc.Base64);
      },
      initCreditCard() {
        this.staxjs = new StaxJs(this.stax_js_token,
        {
          number: {
            id: 'staxjs-number',
            placeholder: '0000 0000 0000 0000',
            style: 'height: 30px; width: 100%; font-size: 15px;',
            type: 'text',
            format: 'prettyFormat'
          },
          cvv: {
            id: 'staxjs-cvv',
            placeholder: 'CVV',
            style: 'height: 30px; width: 100%; font-size: 15px;'
          }
        });

        this.staxjs.showCardForm().then((handler) => {
        }).catch((err) => {
          this.$message.error(err);
        });

        this.staxjs.on('card_form_complete', (message) => {
          this.check_form = true
          this.error_message = ""
        });
        this.staxjs.on('card_form_incomplete', (message) => {
          if(!message.validNumber) {
            this.check_form = false
            this.error_message = "Please enter the correct card number!"
            return;
          }
          if(!message.validCvv) {
            this.check_form = false
            this.error_message = "Please enter the correct cvv number!"
            return;
          }
        });
      },
      loadPage() {
        this.$router.push({});
        this.value = ""
        this.loadPayment()
      },
      auto_complete_placeholder(digit, number) {
        if(digit > number.toString().length) {
          return (this.completion_str((digit - number.toString().length), "0")) + number
        }
        return number.toString();
      },
      completion_str(digit, target) {
        let str = "";
        for(let i = 0;i < digit; i++) {
          str += target
        }
        return str;
      },
      moment,
      downloadFunction(){
        this.downloadLoading = true
        downFileApi('/payment/invoices/'+this.paymentDetail.client_invoice_uuid+'/download').then(res => {
          const url = window.URL.createObjectURL(new Blob([res]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download','invoices.pdf')
          document.body.appendChild(link)
          link.click()
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
          this.downloadLoading = false
        })
      },
      loadPayment(){
        this.paymentLoading = true
        getActionApi('/payment/invoices/unpaid').then(res => {
          this.paymentList = res.data
          if(res.data && res.data.length > 0){
            if(!this.value) {
                this.value = res.data[0].client_invoice_uuid
            }
            this.selectChange()
          } else {
            // this.$message({message: "No invoice payable",type: 'warning'});
          }
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
          this.paymentLoading = false
        })
      },
      selectChange(){
        this.$router.push({
          query: {
            uuid: this.value
          }
        });
        // for(let i = 0; i < this.paymentList.length; i++){
        //   if(this.paymentList[i].client_invoice_uuid == this.value){
        //     this.paymentItem = this.paymentList[i]
        //   }
        // }
        //Call credit card payment rate
        this.paymentDetailLoading = true
        getActionApi('/payment/invoices/' + this.value).then(res => {
          this.paymentDetail = res.data
          this.initCreditCard()
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
          this.paymentDetailLoading = false
        })
      },
      getCardImg(cardNumber){
        if(cardNumber.indexOf('4') == 0)
          return 'VISA'
        else if(cardNumber.indexOf('51') == 0 || cardNumber.indexOf('52') == 0 || cardNumber.indexOf('53') == 0 || cardNumber.indexOf('54') == 0 || cardNumber.indexOf('55') == 0)
          return 'MASTERCARD'
        else if(cardNumber.indexOf('34') == 0 || cardNumber.indexOf('37') == 0)
          return 'AMERICAN_EXPRESS'
        else if(cardNumber.indexOf('6011') == 0)
          return 'DISCOVER'
        else if(cardNumber.indexOf('300') == 0 || cardNumber.indexOf('301') == 0 || cardNumber.indexOf('302') == 0 || cardNumber.indexOf('303') == 0 || cardNumber.indexOf('304') == 0 || cardNumber.indexOf('305') == 0 || cardNumber.indexOf('36') == 0 || cardNumber.indexOf('38') == 0 || cardNumber.indexOf('39') == 0)
          return 'DINERS_CLUB'
        else if(cardNumber.indexOf('3528') == 0 || cardNumber.indexOf('3529') == 0 || cardNumber.indexOf('353') == 0 || cardNumber.indexOf('354') == 0 || cardNumber.indexOf('355') == 0 || cardNumber.indexOf('356') == 0 || cardNumber.indexOf('357') == 0 || cardNumber.indexOf('358') == 0)
          return 'JCB'
        else
          return ''
      },
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .div{background-color: #ffffff;padding: 30px;display: grid;gap: 20px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .title div{display: flex;flex-direction: column;font-size: 16px;}
  .title div .span{color:#5856D6;font-size: 16px;}
  .torbar{display: flex;align-items: center;}
  .torbar div{width: 100%;display: flex;align-items: center;justify-content: center;height: 40px;font-size:14px;border:1px solid transparent;border-bottom:1px solid #CBD5E0;}
  .torbar .act{background-color: #ffffff;border-radius: 6px 6px 0 0;border: 1px solid #CBD5E0;border-bottom-color:#ffffff;color:#5856D6;}
  .torbar div:first-child{width: 240px;}
  .btn{height:40px;min-width:86px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;font-size: 14px;display: flex;align-items: center;justify-content: center;padding: 0 10px;}
  .paymentDiv{display: grid;grid-template-columns:repeat(auto-fit,300px);grid-gap: 20px;margin:30px 0;}
  .paymentDiv .item{box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);padding:20px;border-radius: 10px;cursor: pointer;display:flex;align-items: flex-start;flex-direction: column;}
  .paymentDiv .item .info{width: 100%;display: flex;align-items: flex-start;}
  .paymentDiv .item .info .cadInfo{width: 100%;}
  .paymentDiv .item .delete{color:red;font-size: 14px;cursor: pointer;}
  .cadInfo{display: flex;align-items: center;margin-bottom: 10px;}
  .cadInfo .img{width: 50px;height: 30px;margin-right: 10px;object-fit: contain;}
  .cadInfo div{display: flex;flex-direction: column;font-size: 12px;line-height: 1;font-size: 14px;}
  .cadInfo div span{margin-top:4px;font-size:12px;color: #999999;}
  .paymentDiv .item input{width: 20px;height:20px;}
  .paymentDiv .options{display: flex;justify-content: space-between;align-items: center;width: 100%;font-size: 14px;color:gray;}
  .addBtn{cursor: pointer;background-color: #f7f7f7;font-size: 12px;color: #666666;border-radius: 4px;padding:6px 16px;}
  .payment{font-size: 20px;font-weight: bold;display: flex;flex-direction: column;}
  .payment .conp{padding:10px 20px;font-size: 20px;font-weight: bold;display: flex;flex-direction: column;}
  .payment .conp .record{padding:10px 20px;font-size: 14px;font-weight: normal;width: 500px;display: grid;grid-row-gap: 6px;}
  .payment .conp .record p{display: flex;justify-content: space-between;}
  .payment .conp .record p b{font-size: 16px;}
  .payment .conp .record p:last-child{border-top:1px solid #f7f7f7;padding-top:10px;}
  .payment .conp .record p:last-child b{font-size: 20px;}
  .cardLabel{display: flex;flex-shrink: 0;margin-left:10px;}
  @media (max-width: 768px) {
    .title{flex-direction: column;margin:0 20px;}
    .title div{width: 100%;line-height: 2;}
    .div{padding:20px 5px;}
    .payment .conp .record{width: 100%;}
    .paymentDiv{grid-template-columns:1fr;margin:20px;}
    .torbar{margin:0 20px;}
    .torbar div:first-child{min-width:160px;}
    .payment{margin:0 20px;}
    .payment .conp{padding:10px 0;}
    .payment .conp .record{padding:10px 0;}
    .btn{align-self: flex-end;}
    .addBtn{margin-left:20px;}
    .group{grid-template-columns: 1fr !important;padding:0 20px;grid-gap: 10px !important;}
    .group label{flex-direction: column;min-height: 80px;margin-left:0;height:auto;align-items: flex-start;}
    .input-box{margin:0 !important;}
    .cardLabel{margin-left:0;margin-bottom: 6px;}
    .group label span {
        text-align: left;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
  }

  .form {display: flex;align-items: center;justify-content: center;padding-top: 20px;}
  .group {
      background: white;
      margin-bottom: 20px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;
  }
  .groupDiv{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  label {
      position: relative;
      color: #8898AA;
      font-weight: 300;
      line-height: 40px;
      display: flex;
      flex-direction: column;

  }
  label input{height: 40px;width: 100%;}

  label>span {
      width: 120px;
      margin-right: 10px;
      flex-shrink: 0;
  }

  .field {
      background: transparent;
      font-weight: 300;
      color: #31325F;
      outline: none;
      padding-right: 10px;
      padding-left: 10px;
  }

  .field::-webkit-input-placeholder {
      color: #CFD7E0;
  }

  .field::-moz-placeholder {
      color: #CFD7E0;
  }

  .input-box {
    border-radius: 3px;
    border: 1px solid #DCDFE6;
    width: 100%;
  }


</style>
<style>
  .el-input {font-size: 16px !important;}
</style>
