<template>
  <div id="home">

    <div class="div">
      <div class="title">
        Business Promoter
        <div style="display: flex;">
            <span class="btn" @click="show_qr_code" style="margin-right: 10px;">QR Code</span>
            <span class="btn" @click="show_request">Request</span>
        </div>
      </div>
      <el-table
        :data="tableData"
        :header-cell-style="{ fontSize: '14px', backgroundColor: '#F8F9FA',color:'#4A5468',fontWeight:'normal'}"
        :cell-style="{ fontSize: '14px'}"
        style="width: 100%;">
        <el-table-column
          min-width="200"
          prop="name"
          label="Name">
        </el-table-column>
        <el-table-column
          min-width="200"
          label="Mobile Phone">
          <template slot-scope="scope">
            {{process_phone_number_format(scope.row.phone_number)}}
          </template>
        </el-table-column>

        <el-table-column
          min-width="200"
          label="Email">
          <template slot-scope="scope">
            {{scope.row.email_address}}
          </template>
        </el-table-column>

        <el-table-column
          min-width="130"
          align="center"
          label="Click Throughs">
          <template slot-scope="scope">
            <i v-if="scope.row.email_clicked" class="el-icon-s-promotion" style="font-size: 20px;"></i>
            <el-divider direction="vertical" v-if="scope.row.email_clicked && scope.row.sms_clicked"></el-divider>
            <i v-if="scope.row.sms_clicked" style="font-size: 20px;" class="el-icon-chat-dot-square"></i>
          </template>
        </el-table-column>
        <el-table-column
          min-width="130"
          align="center"
          label="Reviews">
          <template slot-scope="scope">
            <div style="display: flex;justify-content: center;align-items: center;">
              <img src="../assets/imgs/icons/icon_pro6.png" style="width: 20px;height:20px;cursor: pointer;" v-if="scope.row.google_review_clicked" @click="go_to_url(0, scope.row)"/>
              <img src="../assets/imgs/icons/icon_pro5.png" style="width: 20px;height:20px;margin-left: 10px;cursor: pointer;" v-if="scope.row.yelp_review_clicked" @click="go_to_url(1, scope.row)"/>
              <el-popover
                  placement="top-start"
                  title="Feedback"
                  width="400"
                  trigger="hover"
                  style="margin-left: 10px;margin-top: 5px;"
                  :content="scope.row.bad_rating_feedback">
                  <img src="../assets/imgs/icons/bad.png" style="height:25px;cursor: pointer;" v-if="scope.row.bad_rating_feedback" @click="show_bad_feedback(scope.row)" slot="reference"/>
                </el-popover>
            </div>

          </template>
        </el-table-column>
        <el-table-column
          min-width="180"
          align="center"
          label="Marketing Opt In">
          <template slot-scope="scope">
            <i style="font-size: 18px;color: #5856D6;" class="el-icon-success" v-if="scope.row.marketing_permission_allow"></i>
            <i style="font-size: 18px;color: #e36262;" class="el-icon-error" v-else></i>
          </template>
        </el-table-column>
       <!-- <el-table-column
          min-width="120"
          sortable
          align="center"
          label="Refer">
          <template slot-scope="scope" style="display: flex;align-items: center;">
            <div class="label" v-if="!scope.row.friend_refer">
              <a class="no">No</a> <i class="el-icon-warning-outline"></i>
            </div>
            <div class="label" v-else>
              <a class="yes">Yes</a>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          min-width="200"
          label="Date">
          <template slot-scope="scope">
            {{moment(scope.row.created_datetime).format('MM/DD/yyyy HH:mm A')}}
          </template>
        </el-table-column>
        <el-table-column
          min-width="100"
          align="center"
          label="Reminder">
          <template slot-scope="scope">
            <el-link type="primary" title="Send" @click.stop="re_send(scope.row)"><i class="font-size-16 el-icon-position"></i></el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="page_size"
          :total="totalRows">
        </el-pagination>
      </div>
    </div>
    <request ref="requestModel" @ok="getDatas(1)"></request>
    <qr-code ref="qrCodeModel"></qr-code>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { getActionApi,postActionApi } from '@/network/manageApi';
  import moment from 'moment/moment';
  import request from './businessPromoter/Request'
  import qrCode from './businessPromoter/QrCode'
  export default {
    name: 'BusinessPromoter',
    components: {request, qrCode
    },
    data() {
      return {
        totalRows: 0,
        current_page: 1,
        page_size: 10,
        tableData: [],
        table_loading: false
      }
    },
    computed: {

    },
    mounted() {

    },
    created(){
      this.getDatas(1)
    },
    methods:{
      format_phone(localNumber, countryCode) {
        const phoneFormats = {
          "+1": "(XXX) XXX-XXXX", // USA, Canada
          "+44": "07XXX XXXXXX",   // UK Local Mobile
          "+91": "XXXXX-XXXXX",   // India
          "+61": "XXXX XXX XXX",  // Australia
          "+81": "XXX-XXXX-XXXX", // Japan
          "+49": "XXXX XXXXXXX",  // Germany
          "+33": "X XX XX XX XX",  // France
          "+55": "(XX) XXXXX-XXXX" // Brazil
        };
        let format = phoneFormats[countryCode] || "XXXX-XXXX-XXXX";
        let digits = localNumber.replace(/\D/g, "");
        let formattedNumber = "";
        let digitIndex = 0;
        // +44 07577 318158
        for (let char of format) {
            if (char === "X" && digitIndex < digits.length) {
                formattedNumber += digits[digitIndex];
                digitIndex++;
            } else {
                formattedNumber += char;
            }
        }
        return `${countryCode} ${formattedNumber}`;
      },
      process_phone_number_format(phone) {
        if(!phone) {
          return "";
        }
        if(phone == "Anonymous"
        || phone == "anonymous") {
          return "Anonymous";
        }
        let countryCode = this.get_phone_countryCode(phone)
        let phone_cl = phone.replaceAll("-", "").replaceAll(countryCode, "");
        if(countryCode == "+44") {
          if(phone_cl.indexOf("7") == 0 && phone_cl.length == 10) {
            phone_cl = "0" + phone_cl;
          }
        }
        return this.format_phone(phone_cl,countryCode)
      },
      get_phone_countryCode(phone) {
        if(phone.indexOf("+55") != -1) {
          return "+55";
        } else if(phone.indexOf("+33") != -1) {
          return "+33";
        } else if(phone.indexOf("+49") != -1) {
          return "+49";
        } else if(phone.indexOf("+81") != -1) {
          return "+81";
        } else if(phone.indexOf("+61") != -1) {
          return "+61";
        } else if(phone.indexOf("+91") != -1) {
          return "+91";
        } else if(phone.indexOf("+44") != -1) {
          return "+44";
        } else if(phone.indexOf("+1") != -1) {
          return "+1";
        } else {
          return ""
        }
      },
      show_qr_code() {
        this.$refs.qrCodeModel.init()
      },
      show_request() {
        this.$refs.requestModel.init()
      },
      moment,
      getDatas(initSize) {
        if(initSize == 1) {
          this.current_page = 1
        }
        this.table_loading = true
        getActionApi("/review/business-promoter?size=" + this.page_size + "&page=" + this.current_page).then((res) => {
          this.tableData = res.data
          this.totalRows = res.page.totalElements
        }).catch((err) => {

        }).finally((res) => {
          this.table_loading = false
        })
      },
      re_send(row) {
        const that = this
        this.$confirm('Do you want to send it again?', 'Tip', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        }).then(() => {
          that.table_loading = true
          postActionApi("/review/business-promoter/re-send/" + row.business_promoter_uuid).then((res) => {
            that.$message({type: 'success',message: 'Send Success!'});
            that.getDatas()
          }).catch((err) => {
            that.$message.error({message: err.response.data.details.data.message});
          }).finally((res) => {
            that.table_loading = false
          })
        }).catch(() => {
        });
      },
      handleCurrentChange(val) {
        this.current_page = val
        this.getDatas()
      },
      submitForm() {

      },
      go_to_url(type, row) {
        let url = row.google_place_url
        if(type == 1) {
          url = row.yelp_place_url
        }
        window.open(url)
      },
      show_bad_feedback(row) {

      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .div{background-color: #ffffff;padding: 30px;display: grid;gap: 20px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .label{display: flex;align-items: center;justify-content: center;}
  .label .no{background-color: #FED7D7;color: #822727;width: 28px;height: 20px;font-size: 12px;display: flex;align-items: center;justify-content: center;border-radius: 6px;margin-right: 5px;}
  .label .yes{background-color: #b3edff;color: #0075cb;width: 28px;height: 20px;font-size: 12px;display: flex;align-items: center;justify-content: center;border-radius: 6px;margin-right: 5px;}
  .pager{display: flex;justify-content: center;}
  .btn{height:40px;width:86px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;font-size: 14px;display: flex;align-items: center;justify-content: center;}
  @media (max-width: 768px) {
    .title{flex-direction: column;}
    .div{padding:20px 5px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }
  .font-size-16 {font-size: 20px;}
</style>
