import Vue from 'vue'
import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { MessageBox,Notification,Loading } from 'element-ui'

NProgress.configure({ showSpinner: false })

const whiteList = ['/404', '/facebookLogin','/register','/login','/findId','/findPw','/password-reset',
 '/resetPw', '/signup', '/registerFinish']
const white_pattern = /^\/password-reset\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/
const white_pattern_review = /^\/review\/business-promoter\/.*$/
const white_pattern_nologin_pay = /^\/bills\/.*$/
router.beforeEach((to, from, next) => {
  NProgress.start()
  if (store.getters.token) {
    /* has token */
    if (to.path === '/login' || to.path === '/register') {
      next({ path: '/' })
      NProgress.done()
    } else if (whiteList.indexOf(to.path) !== -1 ||
                white_pattern.test(to.path) ||
                white_pattern_review.test(to.path) ||
                white_pattern_nologin_pay.test(to.path)) {
      next()
    } else {
      if (store.getters.nickname == '') {
        store.dispatch('GetUserInfo')
        .catch(err => {
        })
        .finally(() => {
          next()
        })
      } else {
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1 ||
              white_pattern.test(to.path) ||
              white_pattern_review.test(to.path) ||
              white_pattern_nologin_pay.test(to.path)) {
      next()
    } else {
      NProgress.done()
      next({ path: '/login', query: { redirect: to.fullPath } })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
