import { render, staticRenderFns } from "./BusinessPromoterDetail.vue?vue&type=template&id=27f03d95&scoped=true&"
import script from "./BusinessPromoterDetail.vue?vue&type=script&lang=js&"
export * from "./BusinessPromoterDetail.vue?vue&type=script&lang=js&"
import style0 from "./BusinessPromoterDetail.vue?vue&type=style&index=0&lang=css&"
import style1 from "./BusinessPromoterDetail.vue?vue&type=style&index=1&id=27f03d95&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27f03d95",
  null
  
)

export default component.exports